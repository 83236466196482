@import '../generic-components/theme';

$restriction-indicator-sizes: (
  small: (
    font-size: 8px,
    width: 16px,
    height: 16px,
  ),
  medium: (
    font-size: 12px,
    width: 24px,
    height: 24px,
  ),
);

@mixin icon-size($size) {
  @if map-has-key($restriction-indicator-sizes, $size) {
    $size-settings: map-get($restriction-indicator-sizes, $size);
    font-size: map-get($size-settings, font-size);
  } @else {
    @warn "Size #{$size} is not defined in the $icon-sizes map.";
  }
}

@mixin restricted-feature-icon-before($size) {
  content: '\f005';
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900;
  color: $white;
  background-color: $eds-activity-pink;
  border-radius: 100%;
  aspect-ratio: 1/1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  @if map-has-key($restriction-indicator-sizes, $size) {
    $size-settings: map-get($restriction-indicator-sizes, $size);
    font-size: map-get($size-settings, font-size);
    width: map-get($size-settings, width);
    height: map-get($size-settings, height);
  } @else {
    @warn "Size #{$size} is not defined in the $icon-sizes map.";
  }
}

.restricted-feature {
  // Navbar buttons
  &.menu-item {
    div.label {
      color: rgba($color: #fff, $alpha: 0.6) !important;

      &::before {
        @include restricted-feature-icon-before(small);
        text-indent: -1px;
      }
    }
  }

  // Navbar items
  &.menu-dropdown-item {
    pointer-events: none;

    div {
      color: rgba($color: #000, $alpha: 0.6) !important;
      position: relative;
      padding-left: 10px;

      &::before {
        @include restricted-feature-icon-before(small);
        display: inline-flex;
        text-indent: 1px;
        margin-right: $spacing-3;
      }
    }
  }

  // VCW
  &.vcw-intro-container {
    .video-review {
      display: flex;
      gap: $spacing-2;
      align-items: center;
      &::before {
        @include restricted-feature-icon-before(medium);
        margin: initial;
      }
    }

    btn.start-review-btn {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  // Generic Buttons
  &.content-toolbar-required-feature-btn {
    opacity: 0.4;
    pointer-events: none; //for btn components

    button {
      pointer-events: none;
    }
  }

  // Material actions buttons
  &.material-actions-required-feature {
    button {
      opacity: 0.4;
      pointer-events: none;
      &::before {
        @include restricted-feature-icon-before(medium);
        margin: initial;
        margin-left: 12px;
      }
      &.white-blue-bordered.small {
        padding: 5px;
      }
    }
  }

  // Material actions dropdown
  &.dropdown-option-required-feature {
    pointer-events: none;

    &::before {
      @include restricted-feature-icon-before(medium);
      margin: initial;
      margin-left: 12px;
    }

    li {
      label {
        color: rgba($color: #1b1d1d, $alpha: 0.6) !important;
      }

      icon {
        display: none;
      }
    }
  }
}
