// ---------------------------------------------------------------
// Breakpoints
// ---------------------------------------------------------------

@import '../responsive.scss';

// ---------------------------------------------------------------
// Function
// ---------------------------------------------------------------

/**
 * -----------------------
 * This function calculates spacing values based on a multiplier.
 * It takes a multiplier as input and returns the calculated spacing value.
 *
 * @param {number} $multiplier - The multiplier to scale the spacing unit (0.25rem = 4px).
 * @return {number} - The calculated spacing value.
 */
@function space($multiplier) {
  $spacing_unit: 0.25rem;
  @return $spacing_unit * $multiplier;
}

@function elevation($multiplier) {
  @if ($multiplier == 1) {
    @return 3px 8px;
  }
  @if ($multiplier == 2) {
    @return 5px 15px;
  }
  @warn "Shadow token is limited to 1 and 2. See: https://www.figma.com/design/TGIP84RBv7pPKTR9cizLMm/%F0%9F%A7%A9-EDS-Design-System-(WIP)?node-id=55-28866&m=dev";
  @return 3px 8px;
}

// ---------------------------------------------------------------
// Variables
// ---------------------------------------------------------------

// EDS Palette

@import './es-palette';

// Colors
$dark-blue: #024d64;
$light-blue: #29a3c1;
$light-blue-up-1: #80d2f2;
$light-blue-up-2: #59abcb;

$bright-yellow-down-1: #f7a832;
$bright-yellow: #fbaf17;
$bright-yellow-up-1: #fcbf45;

$light-gray: #f3f3f3;
$light-gray-up-1: #e8e8e8;
$medium-gray: #d9d9d9;

$background-gray: #f6f4ef;

$green: #007d00;
$green-up-1: #036e03;
$red: #c64e2a;
$red-up-1: #ec6f64;

// Border radius
$radius-semi-round: 4px;
$radius-small: 5px;
$radius-xl: 12px;
$radius-2xl: 16px;
$radius-medium: 8px;
$radius-strong: 32px;

// Declare spacing variables using the space() function that returns value * space_unit(0.25rem = 4px).
$spacing-1: space(1); // 4px
$spacing-2: space(2); // 8px
$spacing-3: space(3); // 12px
$spacing-4: space(4); // 16px
$spacing-5: space(5); // 20px
$spacing-6: space(6); // 24px
$spacing-7: space(7); // 28px
$spacing-8: space(8); // 32px
$spacing-9: space(9); // 36px
$spacing-10: space(10); // 40px
$spacing-11: space(11); // 44px
$spacing-12: space(12); // 48px
$spacing-14: space(14); // 56px
$spacing-16: space(16); // 64px
$spacing-20: space(20); // 80px

// ---------------------------------------------------------------
// Mixing
// ---------------------------------------------------------------

@mixin inverted-color($bg-color: $dark-blue, $text-color: $white) {
  background-color: $bg-color;
  color: $text-color;
}

@mixin box-center-content() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin soft-shadow() {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

@mixin mid-shadow() {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5);
}

@mixin elevation($multiplier) {
  box-shadow: 0px elevation($multiplier) 0px rgba(0, 0, 0, 0.25);
}

// ---------------------------------------------------------------
// Font Mixin
// ---------------------------------------------------------------
@mixin input-label() {
  color: #357491;
  font-size: 14px;
  font-weight: 600;
}

@mixin input-error() {
  color: $primary-es-coral-300;
}

@mixin input-font-small() {
  border: solid 1px $light-blue;
  font-size: 12px;
  font-style: normal;
}

@mixin input-font-medium() {
  border: solid 1px $light-blue;
  font-size: 14px;
  font-style: normal;
}

@mixin input-font() {
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  font-family: Source Sans Pro;
}

@mixin font-small-1() {
  font-size: 12px;
  line-height: 150%;
}

@mixin font-regular-1() {
  font-size: 16px;
  line-height: 150%;
}

@mixin font-bold-18() {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

@mixin font-bold-body() {
  font-weight: 700;
  font-size: 16px;
}

@mixin font-semibold-body() {
  font-weight: 600;
  font-size: 16px;
}

@mixin font-normalize() {
  margin: unset;
}

@mixin h1-title() {
  color: $neutral-es-black;
  font-size: 40px;
  font-weight: 700;
}

@mixin h1-title-large() {
  color: $neutral-es-black;
  font-size: 48px;
  font-weight: 900;
}

@mixin h2-title() {
  color: $neutral-es-black;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
}

@mixin h2-title-large() {
  color: $neutral-es-black;
  font-size: 32px;
  font-weight: 700;
}

@mixin subtitle() {
  color: $neutral-es-black;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.32px;
}

@mixin square-size($size) {
  width: $size;
  height: $size;
}

// ---------------------------------------------------------------
// Global Classes --------------------------------
// ---------------------------------------------------------------

h1.h1-title {
  @include font-normalize();
  @include h1-title();
}

h1.h1-title-large {
  @include font-normalize();
  @include h1-title-large();
}

h2.h2-title {
  @include font-normalize();
  @include h2-title();
}

h2.h2-title-large {
  @include font-normalize();
  @include h2-title-large();
}

p.p-subtitle {
  @include font-normalize();
  @include subtitle();
}
