/* You can add global styles to this file, and also import other style files */
app-sel {
  position: relative;
  z-index: 1;
}

body {
    margin: 0;
    overflow: auto;
}

html {
    background: #f5f2ed;
}

.ngb-dp-today {
    background-color: #0f759421 !important;
}

.ngb-dp-today.hidden{
    background-color: transparent !important;
}

.bg-primary {
    background-color: #0f7694 !important;
    color: #fff;
}

.pointer-events-none {
    pointer-events: none;
}

.d-flex {
    display: flex;
}

.text-uppercase {
    text-transform: uppercase;
}


@import "./app/common.scss";