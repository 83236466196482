//imports
@import './variables.scss';
@import './responsive.scss';
@import './platform-access/platform-access';

html,
body {
  height: 100%;
  background-color: $color-blue-000;
}

body.modal-open {
  overflow: hidden; //prevent scroll when teaching tools modal is open
}

* {
  font-family: $main-font;
}

/* Global focus state for accessibility contrast*/
*:focus-visible {
  outline: 2px solid #edf8fb !important;
  box-shadow: 0px 0px 0px 4px #1b1d1d !important;
}

.unit-background {
  background-color: rgba(0, 0, 0, 0.25);
}

.unit-inside {
  background-color: rgba(0, 0, 0, 0.4);
}

.unit-selected {
  background-color: rgba(0, 0, 0, 0.6);
}

ngb-datepicker {
  left: -150px !important;
  font-family: $quicksand;
}

//Extra large desktop breakpoint
@media (min-width: 1401px) {
  html {
    .font9 {
      font-size: 9px;
    }

    .font12 {
      font-size: 12px;
    }

    .font13 {
      font-size: 13px;
    }

    .font14 {
      font-size: 14px;
    }

    .font15 {
      font-size: 15px;
    }

    .font16 {
      font-size: 16px;
    }

    .font17 {
      font-size: 17px;
    }

    .font18 {
      font-size: 18px;
    }

    .font20 {
      font-size: 20px;
    }

    .font22 {
      font-size: 22px;
    }

    .font23 {
      font-size: 23px;
    }

    .font24 {
      font-size: 24px;
    }

    .font25 {
      font-size: 25px;
    }

    .font26 {
      font-size: 26px;
    }

    .font28 {
      font-size: 28px;
    }

    .font30 {
      font-size: 30px;
    }

    .font32 {
      font-size: 32px;
    }

    .font35 {
      font-size: 35px;
    }

    .font38 {
      font-size: 38px;
    }

    .font40 {
      font-size: 40px;
    }

    .font41 {
      font-size: 41px;
    }

    .font42 {
      font-size: 42px;
    }

    .font45 {
      font-size: 45px;
    }

    .font46 {
      font-size: 46px;
    }

    .font50 {
      font-size: 50px;
    }

    .font125 {
      font-size: 125px;
    }
  }
}

//Desktop breakpoint
@media (min-width: 1025px) and (max-width: 1400px) {
  html {
    .font12 {
      font-size: 10px;
    }

    .font13 {
      font-size: 11px;
    }

    .font14 {
      font-size: 12px;
    }

    .font15 {
      font-size: 13px;
    }

    .font16 {
      font-size: 14px;
    }

    .font17 {
      font-size: 15px;
    }

    .font18 {
      font-size: 16px;
    }

    .font20 {
      font-size: 18px;
    }

    .font22 {
      font-size: 20px;
    }

    .font23 {
      font-size: 21px;
    }

    .font24 {
      font-size: 22px;
    }

    .font25 {
      font-size: 23px;
    }

    .font26 {
      font-size: 24px;
    }

    .font28 {
      font-size: 26px;
    }

    .font30 {
      font-size: 28px;
    }

    .font32 {
      font-size: 30px;
    }

    .font35 {
      font-size: 33px;
    }

    .font38 {
      font-size: 36px;
    }

    .font40 {
      font-size: 38px;
    }

    .font41 {
      font-size: 39px;
    }

    .font42 {
      font-size: 40px;
    }

    .font45 {
      font-size: 43px;
    }

    .font46 {
      font-size: 44px;
    }

    .font50 {
      font-size: 48px;
    }

    .font125 {
      font-size: 123px;
    }
  }
}

//Tablet breakpoint
@media (min-width: 768px) and (max-width: 1024px) {
  html {
    .font12 {
      font-size: 8px;
    }

    .font13 {
      font-size: 9px;
    }

    .font14 {
      font-size: 10px;
    }

    .font15 {
      font-size: 11px;
    }

    .font16 {
      font-size: 12px;
    }

    .font17 {
      font-size: 13px;
    }

    .font18 {
      font-size: 14px;
    }

    .font20 {
      font-size: 16px;
    }

    .font22 {
      font-size: 18px;
    }

    .font23 {
      font-size: 19px;
    }

    .font24 {
      font-size: 20px;
    }

    .font25 {
      font-size: 21px;
    }

    .font26 {
      font-size: 22px;
    }

    .font28 {
      font-size: 24px;
    }

    .font30 {
      font-size: 26px;
    }

    .font32 {
      font-size: 28px;
    }

    .font35 {
      font-size: 31px;
    }

    .font38 {
      font-size: 34px;
    }

    .font40 {
      font-size: 36px;
    }

    .font41 {
      font-size: 37px;
    }

    .font42 {
      font-size: 38px;
    }

    .font45 {
      font-size: 41px;
    }

    .font46 {
      font-size: 42px;
    }

    .font50 {
      font-size: 46px;
    }

    .font125 {
      font-size: 121px;
    }
  }
}

//Mobile breakpoint
@media (min-width: 320px) and (max-width: 767px) {
  html {
    .font12 {
      font-size: 8px;
    }

    .font13 {
      font-size: 8px;
    }

    .font14 {
      font-size: 10px;
    }

    .font15 {
      font-size: 11px;
    }

    .font16 {
      font-size: 12px;
    }

    .font17 {
      font-size: 13px;
    }

    .font18 {
      font-size: 14px;
    }

    .font20 {
      font-size: 16px;
    }

    .font22 {
      font-size: 17px;
    }

    .font23 {
      font-size: 18px;
    }

    .font24 {
      font-size: 19px;
    }

    .font25 {
      font-size: 20px;
    }

    .font26 {
      font-size: 21px;
    }

    .font28 {
      font-size: 23px;
    }

    .font30 {
      font-size: 25px;
    }

    .font32 {
      font-size: 27px;
    }

    .font35 {
      font-size: 30px;
    }

    .font38 {
      font-size: 33px;
    }

    .font40 {
      font-size: 35px;
    }

    .font41 {
      font-size: 36px;
    }

    .font42 {
      font-size: 37px;
    }

    .font45 {
      font-size: 40px;
    }

    .font46 {
      font-size: 41px;
    }

    .font50 {
      font-size: 45px;
    }

    .font125 {
      font-size: 118px;
    }
  }
}

.scrollable {
  position: fixed !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  width: 100% !important;
  min-height: 89% !important;
  height: 100% !important;
  padding-bottom: 25px !important;
}

input {
  font-family: $main-font;
  padding: 5px 7px;
  height: 28px;
}

/* Text Input and validation classes
    use input-container or input-container-error on ngModel to validate */

.text-input {
  padding: 5px 7px;
  height: 28px;
  font-family: $main-font;
  // font-size: .font16;
  background-color: white;

  &:active {
    background-color: white;
  }

  border-radius: 4px;
}

.input-container {
  border: solid 1px #34adcf !important;
  // height: 30px;
  border-radius: 5px;
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
}

.red-label {
  color: $main-red-color !important;
}

.input-container-error {
  border: solid 1px $main-red-color !important;
  // height: 30px;
  border-radius: 5px;
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
}

/* ########################--- End Inputs ---###################### */

/* Buttons */

button {
  font-family: $main-font;
  background-color: transparent;
  border: unset;
  font-size: 16px;
  padding-block: unset;
  padding-inline: unset;
}

custom-button {
  margin: 5px 0;
  display: table;
}

.btn-blue {
  &:hover {
    cursor: pointer;
  }

  font-family: $main-font;
  // font-size: .font16;
  color: white;
  background-color: $main-blue-color;
  display: block;
  padding: 5px 0;
  width: 100%;
  height: 100%;
  border: 2px solid $main-light-blue-color;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 30px;
  -webkit-transition:
    background 0.4s,
    color 0.4s,
    font-size 0.05s,
    width 0.4s,
    border 0.4s;
  transition:
    background 0.4s,
    color 0.4s,
    font-size 0.05s,
    width 0.4s,
    border 0.4s;
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-white {
  &:hover {
    cursor: pointer;
  }

  font-family: $main-font;
  // font-size: .font16;
  color: $main-light-blue-color;
  background-color: transparent;
  display: block;
  padding: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: 2px solid $main-light-blue-color;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 30px;
  -webkit-transition:
    background 0.4s,
    color 0.4s,
    font-size 0.05s,
    width 0.4s,
    border 0.4s;
  transition:
    background 0.4s,
    color 0.4s,
    font-size 0.05s,
    width 0.4s,
    border 0.4s;
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.close-btn {
  z-index: 10;
  cursor: pointer;

  label {
    cursor: pointer;
    font-family: $quicksand;
    font-weight: 600 !important;
    font-size: 20px;
    color: white !important;
  }
}

/*  ############################## Custom radio button ################################## */

/* Create a custom radio button */

.selector {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

.selector input,
.use-existing-list input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: solid 2px #29a3c1;
  border-radius: 50%;
  margin-top: 8px;
}

/* On mouse-over, add a grey background color */

.selector input:checked ~ .checkmark:hover {
  background-image: none;
  background-color: $lighter-blue-color;
  background-image: url('../assets/svgs/Checkmark.svg');
}

.selector:hover input ~ .checkmark {
  background-color: $lighter-blue-color;
  background-position: center;
  background-repeat: no-repeat;
}

/* When the radio button is checked, add a blue background */

.selector input:checked ~ .checkmark,
.use-existing-list ul li input:checked ~ .checkmark {
  background-color: #29a3c1;
  background-image: url('../assets/svgs/Checkmark.svg');
  background-position: center;
  background-repeat: no-repeat;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.selector input:checked ~ .checkmark:after,
.use-existing-list ul li input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.selector .checkmark:after,
.use-existing-list ul li .checkmark:after {
  top: 5px;
  left: 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #29a3c1;
  background-image: url('../assets/svgs/Checkmark.svg');
  background-position: center;
  background-repeat: no-repeat;
}

/*  ############################## END of Custom radio button ############################## */

.input-icon {
  display: grid;

  i {
    &:hover {
      cursor: pointer;
    }

    position: absolute;
    padding-top: 9px;
    text-align: right;
    right: 22%;
  }
}

/*  ################################ Locker ################################# */

.locker {
  border-radius: 4px;
  background-color: rgba(2, 77, 100, 0.85);
  position: absolute;
  z-index: 25;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}

/*  ############################## END of Locker ############################## */

.blur {
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

/* The switch - the box around the slider */

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

/* Hide default HTML checkbox */

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 25px;
  width: 25px;
  left: 1px;
  bottom: 2.5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* #############################
        SEL Input Dropdown 
 ############################# */

.eds-drop {
  .input {
    position: relative;
    display: inline;
    cursor: pointer;
  }

  input {
    cursor: pointer;
    margin-bottom: 0 !important;
  }

  .arrow {
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
  }

  .dropdown {
    display: none;
    width: calc(100% - 2px);
    margin: auto;
    border: solid 1px #34adcf !important;
    border-radius: 5px;
    box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    position: absolute;
    right: 0;
    cursor: pointer;

    label {
      font-weight: bold;
      display: block;
      width: 100% !important;

      &:last-child {
        border-top: solid 1px #34adcf !important;
      }

      padding-top: 8px !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
      padding-bottom: 8px !important;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .show {
    display: block !important;
    z-index: 9;
  }
}

.font-white {
  color: $main-white !important;
}

// ANIMATIONS
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@font-face {
  font-family: 'recoleta bold';
  src:
    url('../assets/fonts/recoleta/latinotype_-_recoleta_bold-webfont.woff2')
      format('woff2'),
    url('../assets/fonts/recoleta/latinotype_-_recoleta_bold-webfont.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'recoleta semibold';
  src:
    url('../assets/fonts/recoleta/latinotype_-_recoleta_semibold-webfont.woff2')
      format('woff2'),
    url('../assets/fonts/recoleta/latinotype_-_recoleta_semibold-webfont.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

.maintenance-message {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff3cd;
  color: #343a40;
  flex-direction: column;
  max-width: 750px;
  margin: 0 auto;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;

  h4 {
    font-size: 22px;
    font-weight: 700;
    margin-top: 0;
    color: #856404;
  }

  p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.6;
  }
}

/* Instant search requires some global styles for dynamic JS generated elements */
#hits a,
#goal-hit a,
#autosuggest-hit a {
  text-decoration: none;
  color: #000;
}

#hits a:hover,
#goal-hit a:hover,
#autosuggest-hit a:hover {
  text-decoration: underline;
  color: #545656;
}

#goal-hit,
#autosuggest-hit {
  cursor: pointer;
  i {
    margin: 15px 15px 15px 10px;
  }
  .no-results-message {
    padding-left: 35px;
    font-style: italic;
  }
}

#instantSearchContainer .hit-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  &:hover {
    color: #fff;
    background: var(--blue-400, #024d64);
  }

  .hit-title {
    flex-grow: 1;
    text-align: left;
    height: 40px;
    align-items: center;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
  }

  img {
    flex-shrink: 0;
    height: 32px;
    width: auto;
    margin-right: 10px;
  }

  .ageBadge {
    border-radius: 4px;
    border: 1px solid var(--neutral-ES-Gray-300, #545656);
    overflow: hidden;
    color: var(--neutral-ES-Black, #1b1d1d);
    text-overflow: ellipsis;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.32px;
    padding: 0px 8px 0px 8px;
    flex-shrink: 0;
    margin-left: 10px;
    white-space: nowrap;
    text-decoration: none;
    height: 32px;
  }

  li.ais-Hits-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
}
#instantSearchContainer .hit-item:hover {
  .ageBadge {
    border: 1px solid #fff;
    color: #fff;
  }
}

#instantSearchContainer .browse-all-bottom {
  cursor: pointer;
  height: 40px;
  padding: var(--spacing-1, 4px) var(--spacing-3, 12px);
  align-items: center;
  display: flex;
  justify-content: space-between; /* Space between left and right elements */
  font-weight: 700;
  color: #000;
  line-height: 32px;
  border-top: 1px solid var(--color-neutral-200, #e4e4e4);

  a {
    display: flex; /* Aligns icon and title horizontally */
    align-items: center;
    color: #000;
    text-decoration: none; /* Removes underline */
  }

  icon {
    display: flex;
    flex-shrink: 0;
    height: 32px;
    width: 32px;
    align-items: center;
  }

  .hit-title {
    flex-grow: 1;
    padding: 0px 10px;
  }

  &:hover {
    color: #fff;
    background: var(--blue-400, #024d64);

    a {
      color: #fff; /* Ensures link text changes on hover */
    }
  }
}
