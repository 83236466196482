// ---------------------------------------------------------------
// Breakpoints
// ---------------------------------------------------------------

$xs: 320px;
$sm: 640px;
$md: 860px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1440px;

$breakpoints: (
  'xs': $xs,
  'sm': $sm,
  'md': $md,
  'lg': $lg,
  'xl': $xl,
  'xxl': $xxl,
) !default;

@mixin responsive($point) {
  @if map-has-key($breakpoints, $point) {
    // Prints a media query based on the breakpoint
    // i.e.: to apply media query to a class or in root you only have to call @include responsive('lg');
    @media (max-width: map-get($breakpoints, $point)) {
      // add hidden class based on the breakpoint,
      //    .i.e: to hide a class in specific breakpoint (lg) you only have to:
      //        - add 'lg-hidden' besides the class
      @at-root.#{$point}-hidden {
        display: none !important;
      }

      @content;
    }
  } @else {
    @media (max-width: $point) {
      @content;
    }
  }
}

@include responsive(sm);
@include responsive(md);
@include responsive(lg);
@include responsive(xl);
@include responsive(xxl);
