// Green
$primary-es-green-000: #e3faf5;
$primary-es-green-100: #c3e7df;
$primary-es-green-200: #2f9780;
$primary-es-green-300: #1c6d6d;
$curricula-sel-100: #ebecb4;

// Blue
$primary-es-blue-000: #edf8fb;
$primary-es-blue-100: #ceebf2;
$primary-es-blue-200: #3f9eb6;
$primary-es-blue-300: #308091;
$primary-es-blue-400: #185480;

// Neutral
$white: #ffffff;
$black: #000000;
$neutral-es-100: #f8fafc;
$neutral-es-gray-100: #e4e4e4;
$neutral-es-gray-200: #b3b3b3;
$neutral-es-gray-300: #545656;
$neutral-es-gray-800: #454545;
$neutral-es-black: #1b1d1d;
$neutral-es-light-gray: #edeff3;

// Coral
$primary-es-coral-100: #ffdbc7;
$primary-es-coral-300: #c64e2a;
$primary-es-coral-400: #7e3823;

// Yellow
$primary-es-yellow-000: #fff5dc;
$primary-es-yellow-100: #fbe09f;
$primary-es-yellow-200: #f3cc6c;
$primary-es-yellow-300: #d8a73a;
$primary-es-yellow-400: #8f6813;
$yellow-100: #fee7b9;

// Pink
$primary-es-pink-000: #ffeff2;
$primary-es-pink-300: #be5d68;
$eds-activity-pink: #d6296f;
