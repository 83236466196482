//main colors
$main-green-color: #50be9c;
$main-light-orange-color: #fcbf45;
$main-orange-color: #fbaf17;
$thumb-light-blue-color: #a1d4e3;
$goal-blue-color: #34adcf;
$lighter-blue-color: #80d2f2;
$main-light-blue-color: #1185a6;
$main-blue-color: #0f7694;
$main-dark-blue-color: #024d64;
$main-medium-blue-color: #308091;
$main-medium-blue-color-hover: #147488;
$primary-es-blue-400: #185480;
$secondary-bg-color-orange: #ffb876;
$secondary-bg-color-orange-hover: #f9a659;
$light-blue-color: #36acc9;
$main-dark-white-color: rgba(255, 255, 255, 0.616);
$main-dark-gray-color: #4a4a4a;
$main-light-gray-color: #9bacb5;
$main-gray-color: #3f5057;
$main-red-color: #c64e2a;
$main-red-light-color: #ec6f64;
$main-light-error-bg-color: #ffdbc7;
$common-background-color: #f5f2ed;
$common-content-background: #f0f0f0;
$lighter-background-color: #f2f2f2;
$main-white: #ffffff;
$green-mint:#c3e7df;
$color-blue-000: #dff2f7;

//Menu
$scc-main-color: #ffdca7;
$scc-menu-background-color: #3d83a3;
$sel-main-color: #ebecb4;
$sel-menu-background-color: #336199;
$scc-toggle-background-color: #276683;
$sel-toggle-background-color: #244a79;
$menu-background-dark-blue-color: #10384e;
$menu-background-light-blue-color: #347083;
$menu-text-light-blue-color: #ceebf2;

//Detail
$red-error: #d62222;
$red-color: #ff7261;
$red-hover-color: #d26356;
$red-selected-color: #864b44;
$green-color: #94b845;
$green-hover-color: #7e9940;
$green-selected-color: #5b6739;
$edsw-color: #7ec300;
$secondary-dark-black-color: #1b1d1d;

//Onboarding
$primary-es-blue-000: #edf8fb;
$primary-esm-blue-20: #ceebf2;

$primary-es-green-300: #f3f3d4;
$primary-es-green-350: #e5e7a4;

$primary-es-yellow-200: #fdeec9;
$primary-es-yellow-350: #fed495;
$primary-es-yellow-300: #fcedd7;
$primary-es-orange-200: #ec801d;
$primary-es-yellow-400: #8f6813;
$neutral-esm-grey-30: #b3b3b3;
$primary-es-blue-400: #185480;
$primary-es-yellow-100: #FBE09F;

//main fonts
$main-font: "Source Sans Pro", sans-serif;
$quicksand: "Quicksand", sans-serif;
$work-sans: "Work Sans", sans-serif;
$pt-serif: "PT Serif", serif;
$recoleta-bold: "recoleta bold", sans-serif;
$recoleta-semibold: "recoleta semibold", sans-serif;

// trial conversion
$primary-es-blue-100: #ceebf2;
$primary-es-blue-200: #3F9EB6;
$neutral-es-black: #1b1d1d;
$neutral-es-grey-100: #E4E4E4;

//nav bar 
$navbar-height: 70px;

// unified skill colors
:root {
--up-skill-academic-readiness-main: #6ec8b1;
--up-skill-academic-readiness-hover: #296657;
--up-skill-academic-readiness-goal: #acdfd2;

--up-skill-adaptive-main: #ffb168;
--up-skill-adaptive-hover: #8c5724;
--up-skill-adaptive-goal: #ffd2a8;

--up-skill-communication-main: #de97ed;
--up-skill-communication-hover: #764580;
--up-skill-communication-goal: #ecc3f4;

--up-skill-emotional-recognition-main: #85c5f6;
--up-skill-emotional-recognition-hover: #396586;
--up-skill-emotional-recognition-goal: #b9defa;

--up-skill-friendship-main: #a77be8;
--up-skill-friendship-hover: #50327c;
--up-skill-friendship-goal: #ccb3f2;

--up-skill-problem-solving-main: #f3e168;
--up-skill-problem-solving-hover: #847724;
--up-skill-problem-solving-goal: #f8eea8;

--up-skill-resiliency-main: #859dff;
--up-skill-resiliency-hover: #38498c;
--up-skill-resiliency-goal: #b9c7ff;

--up-skill-self-regulation-main: #a7e18e;
--up-skill-self-regulation-hover: #50783f;
--up-skill-self-regulation-goal: #cceebe;

--up-skill-situational-awareness-main: #fad171;
--up-skill-situational-awareness-hover: #896c2b;
--up-skill-situational-awareness-goal: #fce4ae;

--up-skill-vocational-main: #ff9568;
--up-skill-vocational-hover: #8c4424;
--up-skill-vocational-goal: #ffc2a8;
}
